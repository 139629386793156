<template>
	<div class="list" v-if="modelValue">
		<div class="filter">
			<p class="filter-company" v-show="current_user.app_admin==1" @click="companyShow=!companyShow">
				<span class="company-name">{{companyList.find(one=>one.dept_id==filter.dept_id)?.corp_name}}</span>
				<span class="arrow icon-2"></span>
			</p>
			<div class="search-box">
				<span class="search-icon icon-5"></span>
				<input @compositionstart="handleCompositionStart"
					   @compositionend="handleCompositionEnd" ref="input" class="input" type="text" v-model="filter.value" placeholder="搜索监控点名称" @input="input"/>
				<span class="clear-icon icon-10" v-show="filter.value.length>0" @click="clear"></span>
			</div>
		</div>
		<div class="list-box" @scroll="scroll">
			<div class="list-device" v-for="item in list" @click="deviceDetail(item)">
				<div class="device">
					<p class="device-name">{{item.name}}</p>
					<p class="device-id">ID：{{item.device_id}}</p>
				</div>
				<div class="auth" v-if="item.app_admin!=1&&current_user.app_admin!=3">
					<span class="delete icon-8" @click.stop="deleteDev(item)"></span>
				</div>
			</div>
			<no-data v-show="list.length==0"></no-data>
		</div>
		<span class="add icon-11" @click="device.id = '';addDeviceShow=true" v-show="current_user.app_admin!=3"></span>
		<popup v-model="companyShow" title="企业列表">
			<template #content>
				<div class="company-list">
					<p class="list-one" :class="{selected:item.dept_id==filter.dept_id}" v-for="item in companyList" @click="clickItem(item)">
						<span class="corp-name">{{item.corp_name}}</span>
					</p>
					<no-data v-show="companyList.length==0"></no-data>
				</div>
			</template>
		</popup>
		<popup v-model="addDeviceShow" height="100vh" :round="false" :showTop="false" @close="cancel">
			<template #content>
				<div class="add-user">
					<div class="content">
						<div class="col">
							<div class="col-name">监控点名称</div>
							<div class="col-content">
								<new-input v-model="device.name" placeholder="请输入监控点名称"></new-input>
							</div>
						</div>
						<div class="col">
							<div class="col-name">监控点ID</div>
							<div class="col-content">
								<new-input v-model="device.device_id" placeholder="请输入监控点ID"></new-input>
							</div>
						</div>
						<div class="border"></div>
						<div class="col">
							<div class="col-name">监控点负责人</div>
							<div class="col-content">
								<select-people :dept_id="filter.dept_id" :userInfo="device.charge_user" @people-data="peopleData"></select-people>
							</div>
						</div>
						<div class="border" v-if="device.id"></div>
						<div class="table" v-if="device.id">
							<div class="table-name">数据映射</div>
							<div class="tab">
								<span class="tab-one" v-for="item in typeList" :class="{selected:item.value==tab}" @click="tab=item.value;getMappingDetail(item.value)">{{item.name}}</span>
							</div>
							<div class="table-box">
								<p class="table-header">
									<span class="cell">污染物编号</span>
									<span class="cell">污染物名称</span>
								</p>
								<div class="table-body" v-if="device.mapping.length>0">
									<div class="table-one" v-for="item in device.mapping.filter(one=>one.type==tab)">
										<span class="cell">{{item.key}}</span>
										<div class="cell">
											<input @compositionstart="(e)=>compositionStart(e,item)"
												   @compositionend="(e)=>compositionEnd(e,item)" type="text" v-model="item.value" @input="(e)=>mapInput(e,item)" style="flex: 1;height: 100%;text-align:center;border-radius: 0;padding:0 .05rem;">
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="opt">
						<span class="btn default" @click="cancel">取消</span>
						<span class="btn" @click="addDevice" style="margin-left: .1rem">保存</span>
					</div>
				</div>
			</template>
		</popup>
		<popup v-model="addMapShow" height="100vh" :round="false" :showTop="false">
			<template #content>
				<div class="add-user">
					<div class="content">
						<div class="table">
							<div class="tab">
								<span class="tab-one" v-for="item in typeList" :class="{selected:item.value==tab}" @click="tabActive(item.value)">{{item.name}}</span>
							</div>
							<div class="table-box">
								<p class="table-header">
									<span class="cell">污染物编号</span>
									<span class="cell">污染物名称</span>
								</p>
								<div class="table-body">
									<div class="table-one" v-for="item in device.mapping.filter(one=>one.type==tab)">
										<p class="cell">{{item.key}}</p>
										<div class="cell">
											<input @compositionstart="(e)=>compositionStart(e,item)"
													 @compositionend="(e)=>compositionEnd(e,item)" type="text" v-model="item.value" @input="(e)=>mapInput(e,item)" style="flex: 1;height: 100%;text-align:center;border-radius: 0;padding:0 .05rem;">
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="opt">
						<span class="btn default" @click="cancel">取消</span>
						<span class="btn" @click="()=>addMap()" style="margin-left: .1rem">保存</span>
					</div>
				</div>
			</template>
		</popup>
		<van-dialog v-model:show="mapShow" title="数据映射中..." :show-cancel-button="true" :show-confirm-button="false">
			<div style="display: flex;justify-content: center">
				<img style="width: 1rem;height: 1rem;" src="@/assets/img/loading.gif" alt="" />
			</div>
			<p style="display: flex;justify-content: center;font-size: .12rem;padding: .1rem 0;">数据同步加载中，请稍后...</p>
		</van-dialog>
		<van-dialog v-model:show="addDevicesSuccessShow" title="操作提醒" confirm-button-text="设置数据映射" :show-cancel-button="true" @confirm="setSourceData" @cancel="cancel">
			<p style="height:.4rem;display: flex;align-items: center;justify-content: center"><span class="icon-12" style="color: #56c05b;font-size: .18rem"></span>添加监控点成功！</p>
		</van-dialog>
    </div>
</template>

<script>
    import {defineComponent, toRefs, reactive,computed,watch} from "vue"
	import {useRouter} from "vue-router"
	import {useStore} from "vuex"
	import {Dialog,Toast} from "vant"
	import api from "@/api/methods"
	import * as dd from 'dingtalk-jsapi';
	import formValid from '@/components/input/formValid';
	import myPublic from '@/utils/public';
    export default defineComponent({
        name: "device",
        props: {
			modelValue: {
                type: Boolean,
                default: false
            }
        },
		mixins:[formValid],
        setup(props, {emit}) {
        	const router = useRouter();
        	const store = useStore();
            const state = reactive({
				app_admin_list:[
					{
						name:'超级管理员',
						value:1,
						color:'#ffa61a',
						border_color:'#ffa61a',
					},{
						name:'管理员',
						value:2,
						color:'#999',
						border_color:'#999',
					},{
						name:'企业成员',
						value:3,
						color:'#999',
						border_color:'#999',
					},
				],
				companyShow:false,
				companyList:[],
				list:[],
				page:1,
				page_size:15,
				total:0,
				next_time:Date.now(),
				filter:{
					value:'',
					dept_id:'',
				},
				device:{
					id:'',
					name:'',
					device_id:'',
					charge_user:[],
					mapping:[]
				},
				addDeviceShow:false,
				addMapShow:false,
				addDevicesSuccessShow:false,
				mapShow:false,
				typeList:[
					{
						name:'实时数据',
						value:2011,
					},
					{
						name:'小时数据',
						value:2061,
					},
					{
						name:'天数据',
						value:2031,
					}
				],
				tab:2011,
				isPc:dd.pc
            });
			const current_user = computed(()=>{
				return store.state.user.current_user
			});
			watch(()=>props.modelValue,(val)=>{
				if(val){
					init();
				}
			},{immediate:true,deep:true});
			function peopleData(user) {
				state.device.charge_user = user;
			}
            function getCompanyList(page=1) {
				let p ={
					page:page,
					page_size:999
				};
				state.page=page;
				return api.company.getCompanyList(p).then((res)=>{
					if(res.code==0){
						if(Array.isArray(res.data.list)){
							res.data.list.forEach((item)=>{
								item.admin = item.admin_user?item.admin_user[0].name:'--';
							});
							if(!state.filter.dept_id){
								if(current_user.value.dept_id){
									state.filter.dept_id = current_user.value.dept_id;
								}else{
									state.filter.dept_id = res.data.list[0]?.dept_id;
								}
							}
							state.companyList = res.data.list;
						}
					}
					return true;
				})
            }
            function getList(page=1) {
				let p ={
					page:page,
					page_size:state.page_size
				};
				state.page=page;
				if(state.filter.dept_id){
					p.dept_id = state.filter.dept_id;
				}
				if(state.filter.value){
					p.name = state.filter.value;
				}
				api.device.deviceList(p).then((res)=>{
					if(res.code==0){
						if(Array.isArray(res.data.list)){
							if(page == 1){
								state.list = res.data.list;
							}else{
								state.list.push(...res.data.list);
							}
							state.total = res.data.total;
						}
					}
				})
            }
			function scroll(e) {
				if (e.target.clientHeight + e.target.scrollTop + 1 > e.target.scrollHeight) {
					if (Date.now() - state.next_time>500&&Math.ceil(state.total/state.page_size)>state.page) {
						state.next_time = Date.now();
						state.page++;
						getList(state.page);
					}
				}
			}
			function back(){
				router.go(-1);
			}
			function clickItem(item){
				if(state.filter.dept_id != item.dept_id){
					state.filter.dept_id = item.dept_id;
				}
				state.companyShow = false;
				getList();
			}
			function clear(){
            	state.filter.value = '';
				getList();
			}
			function deleteDev(item) {
				Dialog.confirm({
					title: '操作提醒',
					message:`删除监控点【${item.name}】会同时删除该监控点下报警规则，是否继续？`
				}).then(() => {
					deleteDevice(item);
				}).catch(() => {
					// on cancel
				});
			}
			function deleteDevice(item) {
				let p = {
					id:item.ID
				};
				api.device.deviceDelete(p).then((res)=>{
					if(res.code==0){
						Toast.success({
							message: "删除成功!",
						});
						getList();
					}
				})
			}
			function cancel(){
				state.device.name = '';
				state.device.device_id = '';
				state.device.charge_user = [];
				state.device.mapping = [];
				state.tab = 2011;
				state.addDeviceShow = false;
				state.addMapShow = false;
				state.mapShow = false;
				state.addDevicesSuccessShow = false;
			}
			async function init(){
				await getCompanyList();
				getList();
			}
            return {
                ...toRefs(state),
				current_user,
				back,
				getList,
				clickItem,
				clear,
				scroll,
				deleteDev,
				peopleData,
				cancel
            }
        },
		data(){
			return {
				isComposing:false
			}
		},
		methods:{
			handleCompositionStart() {
				this.isComposing = true;
			},
			handleCompositionEnd(event) {
				this.isComposing = false;
				this.input(event);
			},
			input(e){
				if(this.isComposing) return;
				this.getList();
			},
			compositionStart() {
				this.isComposing = true;
			},
			compositionEnd(event,item) {
				this.isComposing = false;
				this.mapInput(event,item);
			},
			mapInput(e,item){
				if(this.isComposing) return;
				item.is_edit = true;
			},
			tabActive(type){
				if(this.tab!=type){
					this.tab = type;
					this.getMapData(type);
				}
			},
			getMapData(type){
				if(type==2011){
					if(this.device.mapping.filter(one=>one.type==type).length==0){
						this.getSourceData();
					}
				}else if(type==2061){
					if(this.device.mapping.filter(one=>one.type==type).length==0){
						this.getHourSourceData();
					}
				}else if(type==2031){
					if(this.device.mapping.filter(one=>one.type==type).length==0){
						this.getDaySourceData();
					}
				}
			},
			getSourceData(){
				api.device.getSourceData({device_id:this.device.device_id}).then((res)=>{
					if(res.code==0){
						if(Array.isArray(res.data.list)){
							res.data.list.forEach((item)=>{
								item.type = 2011;
								item.is_edit = false;
							});
							this.device.mapping.push(...res.data.list);
							this.addMapShow = true;
						}
					}
					this.mapShow = false;
				})
			},
			getHourSourceData(){
				api.device.getHourSourceData({device_id:this.device.device_id}).then((res)=>{
					if(res.code==0){
						if(Array.isArray(res.data.list)){
							res.data.list.forEach((item)=>{
								item.type = 2061;
								item.is_edit = false;
							});
							this.device.mapping.push(...res.data.list);
						}
					}
				})
			},
			getDaySourceData(){
				api.device.getDaySourceData({device_id:this.device.device_id}).then((res)=>{
					if(res.code==0){
						if(Array.isArray(res.data.list)){
							res.data.list.forEach((item)=>{
								item.type = 2031;
								item.is_edit = false;
							});
							this.device.mapping.push(...res.data.list);
						}
					}
				})
			},
			setSourceData(){
				this.addDevicesSuccessShow = false;
				this.mapShow = true;
				this.getSourceData();
			},
			async addDevice() {
				let p = {
					name:this.device.name,
					device_id:this.device.device_id,
					charge_user:this.device.charge_user.map(one=>one.user_id).join(','),
				};
				if(this.device.id){
					p.id = this.device.id;
					// if(this.device.mapping.filter(one=>!(one.value.trim())).length>0){
					// 	return this.$toast({
					// 		message: "污染物名称不得为空！",
					// 		type:'fail'
					// 	});
					// }
					if(this.device.mapping.filter(one=>one.is_edit&&one.ID).length>0){
						await this.mappingEdit();
					}
					if(this.device.mapping.filter(one=>!one.ID).length>0){
						await this.addMap();
					}
				}else{
					p.dept_id = this.filter.dept_id;
				}
				if(!this.device.name){
					return this.$toast({
						message: "请填写监控点名称！",
						type:'fail'
					});
				}
				if(!this.device.device_id){
					return this.$toast({
						message: "请填写监控点ID！",
						type:'fail'
					});
				}
				if(this.device.charge_user.length==0){
					return this.$toast({
						message: "请选择监控点负责人！",
						type:'fail'
					});
				}
				api.device[this.device.id?'deviceEdit':'addDevice'](p).then((res)=>{
					if(res.code==0){
						if(!this.device.id){
							this.device.id = res.data;
							this.addDevicesSuccessShow = true;
						}else{
							this.cancel();
						}
						this.getList();
					}
				})
			},
			addMap() {
				let p = {

				};
				p.device_id = this.device.id.toString();
				p.dept_id = this.filter.dept_id;
				p.mapping_data = JSON.stringify(this.device.mapping.filter(one=>!one.ID).map(one=>{
					return {
						key:one.key,
						value:one.value,
						type:one.type,
					}
				}));
				if(this.device.mapping.length==0){
					this.cancel();
					return this.$toast({
						message: "请重新获取数据映射！",
						type:'fail'
					});
				}
				// if(this.device.mapping.filter(one=>!one.ID).filter(one=>!(one.value.trim())).length>0){
				// 	return this.$toast({
				// 		message: "污染物名称不得为空！",
				// 		type:'fail'
				// 	});
				// }
				api.device.addDeviceMapping(p).then((res)=>{
					if(res.code==0){
						this.$toast({
							message: "设置数据映射成功！",
							type:'success'
						});
						this.cancel();
						this.getList();
					}
				})
			},
			mappingEdit(){
				let p = {

				};
				p.device_id = this.device.id.toString();
				p.mapping_data = JSON.stringify(this.device.mapping.filter(one=>one.is_edit).map(one=>{
					return {
						id:one.ID,
						value:one.value
					}
				}));
				return api.device.mappingEdit(p).then((res)=>{
					if(res.code==0){
						this.$toast({
							message: "数据映射修改成功！",
							type:'success'
						});
					}
				})
			},
			deviceDetail(item) {
				let p ={
					id:item.ID,
				};
				api.device.deviceDetail(p).then(async (res)=>{
					if(res.code==0){
						this.device.id = res.data.ID;
						this.device.name = res.data.name;
						this.device.device_id = res.data.device_id;
						this.device.charge_user = res.data.user_info?res.data.user_info:[];
						this.addDeviceShow = true;
						await this.getMappingDetail();
					}
				})
			},
			getMappingDetail(type=2011){
				let p ={
					device_id:this.device.id,
					type:type,
				};
				return api.device.getMappingDetail(p).then((res)=>{
					if(res.code==0){
						if(this.device.mapping.filter(one=>one.type==type).length==0){
							if(res.data&&Array.isArray(res.data)){
								res.data.forEach(one=>{
									one.is_edit = false;
									one.value = one.key_mapping;
									delete one.key_mapping;
								});
								this.device.mapping.push(...res.data);
							}
						}
						if(this.device.mapping.filter(one=>one.type==type).length==0){
							this.$dialog.confirm({
								title: '操作提醒',
								message:`上次${this.typeList.find(one=>one.value==type).name}映射未获取成功，是否重新获取数据映射？`
							}).then(() => {
								this.getMapData(type);
							}).catch(() => {
								// on cancel
							});
						}
					}
				})
			}
		}
    })
</script>

<style scoped lang="less">
	.list{
		position: relative;
		flex: 1;
		display: flex;
		flex-direction: column;
		border-top: .03rem solid #eef2f5;
		overflow: hidden;
		.filter{
			position: relative;
			width: calc(100% - .4rem);
			display: flex;
			align-items: center;
			flex-shrink: 0;
			padding: .1rem .2rem;
			background-color: #fff;
			.filter-company{
				max-width: 2rem;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				margin-right: .1rem;
				cursor: pointer;
			}
			.search-box{
				flex: 1;
				position: relative;
				display: inline-flex;
				align-items: center;
				overflow: hidden;
				.search-icon{
					position: absolute;
					left: .08rem;
					font-size: .16rem;
					color: #c4ccd9;
				}
				.input{
					flex: 1;
					height: .34rem;
					border-radius: 2em;
					background-color: #eef2f5;
					padding:0 .25rem 0 .3rem;
					overflow: hidden;
				}
				.clear-icon{
					position: absolute;
					right: .08rem;
					font-size: .16rem;
					color: #c4ccd9;
				}
			}
			.btn{
				display: inline-flex;
				align-items: center;
				height: .36rem;
				background-color: #2ca0f7;
				color: #fff;
				border-radius: 2em;
				padding:0 .1rem;
				margin-left: .1rem;
				cursor: pointer;
				.icon{
					font-size: .12rem;
					margin-right: .05rem;
				}
				&:active{
					opacity: .8;
				}
			}
		}
		.list-box{
			flex: 1;
			background-color: #fff;
			padding: 0 .2rem;
			overflow-y: auto;
			.list-corp{
				padding: .1rem 0;
				border-bottom: 1px solid #efefef;
				.corp-name{
					height: .24rem;
					display: flex;
					align-items: center;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					font-size: .16rem;
					font-weight: bolder;
				}
				&:last-of-type{
					border-bottom: none;
				}
			}
			.list-user{
				padding: .1rem 0;
				display: flex;
				border-bottom: 1px solid #efefef;
				.avatar{
					width: .5rem;
					height: .5rem;
					display: inline-flex;
					justify-content: center;
					align-items: center;
					color: #fff;
					background-color: #2aa0f7;
					border-radius: 50%;
					border: 2px solid #fff;
					font-size: .12rem;
				}
				.role{
					flex: 1;
					display: flex;
					flex-direction: column;
					margin:0 .1rem;
					overflow: hidden;
					.role-name{
						flex: 1;
						display: flex;
						align-items: center;
						overflow: hidden;
						.name{
							min-width: 0;
							color: #333;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
							font-size: .16rem;
							font-weight: bolder;
						}
						.app-admin{
							flex-shrink: 0;
							border-radius: 1em;
							border: 1px solid #fff440;
							color: #fff440;
							padding:.02rem .08rem;
							margin-left: .05rem;
							font-size: .12rem;
						}
					}
					.role-mobile{
						flex: 1;
						display: flex;
						align-items: center;
						overflow: hidden;
						.mobile{
							flex: 1;
							color: #999;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
						}
					}
				}
				.auth{
					display: inline-flex;
					align-items: center;
					.switch{
						color: #2aa0f7;
						margin: 0 .1rem;
						cursor: pointer;
					}
					.delete{
						color: #F56C6C;
						margin:0 .1rem;
						cursor: pointer;
					}
				}
				&:last-of-type{
					border-bottom: none;
				}
			}
			.list-device{
				padding: .1rem 0;
				display: flex;
				border-bottom: 1px solid #efefef;
				cursor: pointer;
				.device{
					flex: 1;
					overflow: hidden;
					.device-name{
						min-width: 0;
						color: #333;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
						font-size: .16rem;
						font-weight: bolder;
					}
					.device-id{
						color: #999;
						display: flex;
						align-items: center;
						height: .3rem;
						overflow: hidden;
					}
				}
				.auth{
					display: inline-flex;
					align-items: center;
					.switch{
						color: #2aa0f7;
						margin: 0 .1rem;
						cursor: pointer;
					}
					.delete{
						color: #F56C6C;
						margin:0 .1rem;
						cursor: pointer;
					}
				}
				&:last-of-type{
					border-bottom: none;
				}
			}
			.col{
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: .24rem;
				color: #999;
			}
		}
		.add{
			position: absolute;
			bottom: .5rem;
			right: .3rem;
			display: flex;
			justify-content: center;
			align-items: center;
			width: .5rem;
			height: .5rem;
			border-radius: 50%;
			color: #fff;
			background-color: #2aa0f7;
			cursor: pointer;
			box-shadow: 0 0 15px rgba(0,0,0,.15);
			&:active{
				opacity: .8;
			}
		}
	}
	.company-list{
		height: 100%;
		padding: 0 .25rem;
		overflow-y: auto;
		.list-one{
			display: flex;
			align-items: center;
			height: .4rem;
			border-bottom: 1px solid #efefef;
			cursor: pointer;
			.corp-name{
				flex: 1;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
		.selected{
			.corp-name{
				color: #2aa0f7;
			}
		}
	}
	.add-user{
		height: 100%;
		display: flex;
		flex-direction: column;
		.content{
			flex: 1;
			overflow-y: auto;
			.col{
				padding: .05rem .15rem .15rem .15rem;
				.col-name{

				}
				.col-content{
					padding-top: .05rem;
				}
			}
		}
		.opt{
			flex-shrink: 0;
			display: flex;
			align-items: center;
			height: .5rem;
			border-top: 1px solid #efefef;
			padding: 0 .15rem;;
			.btn{
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				height: .4rem;
				border-radius: .04rem;
				background-color: #2aa0f7;
				color: #fff;
				font-size: .12rem;
				border: 1px solid transparent;
				cursor: pointer;
				&:active{
					opacity: .8;
				}
			}
			.default{
				background-color: #fff;
				color: #2aa0f7;
				border: 1px solid #2aa0f7;
			}
		}
	}
	.border{
		height: .06rem;
		background-color: #eef2f5;
	}
	.table{
		flex: 1;
		padding:.2rem .15rem;
		overflow: hidden;
		.table-name{
			padding:.05rem 0;
		}
		.table-box{
			display: flex;
			flex-direction: column;
			overflow: hidden;
			border-left:1px solid #eef2f5;
			border-top:1px solid #eef2f5;
			.table-header{
				flex-shrink: 0;
				display: flex;
				height: .5rem;
				border-top-left-radius: .04rem;
				border-top-right-radius: .04rem;
				background-color: #2aa0f7;
				color: #fff;
			}
			.table-body{
				flex: 1;
				display: flex;
				flex-direction: column;
				overflow: hidden;
				.table-one{
					flex-shrink: 0;
					display: flex;
					color: #353944;
					height: .5rem;
					overflow: hidden;
					&:nth-child(even){
						background-color: #eef2f5;
						input{
							background-color: #eef2f5;
						}
					}
				}
			}
		}
		.cell{
			flex: 1;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			border-right:1px solid #eef2f5;
			border-bottom:1px solid #eef2f5;
			overflow: hidden;
		}
	}
	.tab{
		flex-shrink: 0;
		display: flex;
		justify-content: space-between;
		background-color: #eef2f5;
		padding: .03rem;
		border-radius: .04rem;
		margin: .1rem 0;
		.tab-one{
			flex: 1;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			height: .34rem;
			border-radius: .04rem;
			cursor: pointer;
		}
		.selected{
			background-color: #fff;
		}
	}
</style>
